<template>
  <ion-page>   
    <ion-content :fullscreen="true">

      <Header></Header>
        
      <div class="container">
        <section id="options">
          <div class="py-md">
              <div class="list-nav">
              <ion-grid>
                  <ion-row>
                  <ion-col size="12" size-lg="6">
                      <ion-button @click="exit" shape="round" style="margin-bottom: 1rem" color="dark" fill="outline">
                          <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                          <ion-label>Volver</ion-label>
                      </ion-button>
                      <h2>Editar Patrocinio</h2>
                  </ion-col>
                  <ion-col size="12" size-md="6">
                      <ion-buttons class="options-nav">
                          <ion-toggle v-model="element.active" color="success"></ion-toggle>

                          <ion-button @click="confirmDeleteElement($event)" >
                              <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
                          </ion-button>
                          <ConfirmPopup></ConfirmPopup>
                      </ion-buttons>
                  </ion-col>
                  </ion-row>
              </ion-grid>
              </div>
          </div>
        </section>

        <section id="content">
          <ion-grid style="margin-bottom: 3rem">
            <ion-row>
              <ion-col size="12" size-lg="7">
                <ion-grid>
                  <ion-row style="padding-bottom: 2rem">
                    <h5 class="edit-title">Detalles</h5>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.name.$errors.length }">
                        <label for="name">Nombre</label>
                        <InputText class="input" id="name" placeholder="Nombre del patrocinio" type="text" v-model.trim.lazy="v$.element.name.$model" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.name.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>

                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.dateStart.$errors.length }">
                        <label for="dateStart">Fecha de Inicio</label>
                        <Calendar autocomplete="off" id="dateStart" class="input secondary-input" v-model.lazy="v$.element.dateStart.$model" placeholder="Elegir día" dateFormat="dd/mm/yy" />
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.dateStart.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>

                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.dateEnd.$errors.length }">
                        <label for="dateEnd">Fecha de Finalización</label>
                        <Calendar autocomplete="off" id="dateEnd" class="input" v-model.lazy="v$.element.dateEnd.$model" placeholder="Elegir día" dateFormat="dd/mm/yy" />
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.dateEnd.$errors" :key="index">
                            <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>

                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.price.$errors.length }">
                        <label for="price">Precio</label>
                        <InputNumber id="price" class="inputNumber secondary-input" v-model.lazy="v$.element.price.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.price.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.description.$errors.length }">
                        <label for="description">Descripción</label>
                        <Editor v-model.trim.lazy="v$.element.description.$model" placeholder="Descripción" id="description" editorStyle="min-height: 160px; height: auto"/>

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.description.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                  </ion-row>

                  <ion-row class="py-sm">
                    <ion-col size="12">
                      <h5 class="edit-title">Imagen</h5>
                          <FileUpload
                              mode="basic"
                              name="image"
                              :multiple="false"
                              accept="image/*"
                              :maxFileSize="5000000"
                              :customUpload="true"
                              @uploader="handleImageUpload"
                              :auto="true"
                              chooseLabel="Subir Imagen"
                              class="uploader"
                              ref="uploader"
                          />
                            <p style="margin-top: 1rem;" v-if="!images.length">No hay imagen</p>
                            <draggable class="imagenes" :list="images" @change="updateImageList">
                              <div
                                class="imagen-container" v-for="image in images" :key="image"
                              >
                                <Image :src="image.objectURL" alt="image preview" class="imagen" preview />
                                <ion-buttons class="imagen-remover">
                                    <ion-button @click="handleImageRemove($event, image.objectURL)">
                                    <ion-icon :icon="closeCircleOutline" color="danger"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                              </div>
                            </draggable>
                      </ion-col>
                  </ion-row>

                </ion-grid>

                <ion-button @click="save" :disabled="v$.element.$invalid" color="dark" expand="block" style="margin-top:1rem" shape="round">
                    <ion-icon slot="start" :icon="saveOutline"></ion-icon>
                    <ion-label>Guardar</ion-label>
                </ion-button>
                <ion-button @click="showPreview" color="dark" fill="outline" expand="block"  class="hide-xl hide-lg" style="margin-top:0.5rem" shape="round">
                    <ion-label>Previsualizar</ion-label>
                </ion-button>
              </ion-col>
              <ion-col size="12" size-lg="5" class="hide-xs hide-sm hide-md">
                  <DishPreview :data="element"></DishPreview>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>
      </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonButton, IonButtons, modalController, IonToggle } from '@ionic/vue';
import { saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline, createOutline, duplicateOutline } from 'ionicons/icons';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import ConfirmPopup from 'primevue/confirmpopup';
import InputNumber from 'primevue/inputnumber';
import Image from 'primevue/image';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import DishPreview from './components/DishPreview.vue';

import { VueDraggableNext } from 'vue-draggable-next'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'EditPromotion',
  components: {
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    ConfirmPopup,
    IonIcon,
    IonLabel,
    IonButton,
    IonButtons,
    Calendar,

    InputText,
    FileUpload,
    Image,
    InputNumber,
    Editor,
    IonToggle,
    DishPreview,
    draggable: VueDraggableNext,
  },
  data() {
      return {
        element: {
            active: true,
            name: '',
            price: 0,
            description: '',
            images: [],
            dateStart: undefined,
            dateEnd: undefined,
        },
        language: 'es',
        images: [],

        dialogImageUrl: '',
        dialogVisible: false,
        languages: [
                {name: 'Español', code: 'es'},
        ],
        lastVariationKey: 0,
        expandedRows: [],
      };
    },
    validations() {
        return {
            element: {
                name: {required},
                price: {},
                description: {},
                dateStart: {required},
                dateEnd: {required},
            },
        }
    },
    computed: {
        ...mapState('lgdc', {
            patrocinio: state => state.patrocinio,
        }),

        ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('lgdc',['getPatrocinio','uploadImage', 'addPatrocinio', 'updatePatrocinio', 'deletePatrocinio']),
      ...mapActions(['uploadImage', 'uploadImageMenu']),

      // Images
      handleImageUpload(event){
        event.files.forEach(file => {
          const epoch = new Date().getTime();
          file.id = epoch + '-' + Math.floor(Math.random() * 1000000);
          
          this.images.push(file);
          this.uploadImage({ruta: '/evolbe-modules/lgdc/patrocinios', img: file});
          this.element.images.push({
            '256': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Flgdc%2Fpatrocinios%2Fthumbnails%2F' + file.id + '_256x256.webp?alt=media',
            '512': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Flgdc%2Fpatrocinios%2Fthumbnails%2F' + file.id + '_512x512.webp?alt=media',
            '1024': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Flgdc%2Fpatrocinios%2Fthumbnails%2F' + file.id + '_1024x1024.webp?alt=media',
            '2048': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Flgdc%2Fpatrocinios%2Fthumbnails%2F' + file.id + '_2048x2048.webp?alt=media',
          });
        });

        this.$refs.uploader.clear();
      },
      handleImageRemove(event, url) {
        const pos0 = this.images.findIndex(x => x.objectURL === url);
        this.images.splice(pos0, 1);

        const pos = this.element.images.findIndex(x => x[512] === url);
        this.element.images.splice(pos, 1);
      },
      handleImagePreview(event, url) {
        this.dialogImageUrl = url;
        this.dialogVisible = true;
      },
  
      // Actions
      save(){
        this.element.dateStart = new Date(this.element.dateStart).getTime();
        this.element.dateEnd = new Date(this.element.dateEnd).getTime();

        console.log(this.element)

        if(this.$route.params.id === 'new'){
          this.element.dateCreated = new Date().getTime();
          this.element.dateUpdated = new Date().getTime();

          this.addPatrocinio(this.element);
          this.exit();
        } else {
          this.element.dateUpdated = new Date().getTime();

          this.updatePatrocinio({data: this.element, id: this.$route.params.id});
          this.exit();
        }
      },
      confirmDeleteElement(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar ese elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.deletePatrocinio(this.$route.params.id);
                    this.exit();
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },

      exit(){
          this.images = [];
          this.$router.go(-1);
      },
      
      async showPreview(){
        const modal = await modalController
            .create({
                component: DishPreview,
                cssClass: 'auto-height',
                componentProps: {
                    element: this.element
                },
            })
        return modal.present();
      }
    },
    beforeMount() {
      if(this.$route.params.id === 'new'){
          //Nuevos
      } else {
        this.getPatrocinio(this.$route.params.id).then(() => {
            if(this.patrocinio){
                this.element = this.patrocinio;

                // Load Images
                if(this.element && !this.images.length){
                  this.element.images.forEach(img => {
                    this.images.push({objectURL: img['512'], status: 'upload'});
                  });
                }

                this.element.dateStart = new Date(this.patrocinio.dateStart);
                this.element.dateEnd = new Date(this.patrocinio.dateEnd);
            }
        });
      }
    },
  setup() {
    return { v$: useVuelidate(), saveOutline, trashOutline, arrowBackOutline, moveOutline, createOutline, addCircleOutline, removeCircleOutline, duplicateOutline, closeCircleOutline};
  }
});
</script>

<style scoped>

  .edit-title{
    padding-bottom: 1rem;
  }

  .imagenes{
    margin-top: 1rem;
    display: flex;
    overflow: hidden;
    display: grid;
  }

  .imagenes .imagen-container{
    position: relative;
    margin: 0.5rem
  }

  .imagenes .imagen-container .imagen{
    width: 100%;
    height: 148px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .imagenes .imagen-container .imagen-remover{
    position: absolute;
    top: 8px;
    right: 8px;
  }

 .onlyDelivery{
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
    --padding-end: 0;
    font-weight: bold;
 }

  .preview{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center center;
  }


</style>